<!--
 * @Author: lijun
 * @Date: 2021-11-06 16:00:27
 * @LastEditTime: 2022-08-22 14:35:36
 * @LastEditors: ch3nh2
 * @Description: 消息调度-新消息
 * @FilePath: /zhy_dataview_fe/src/pages/NoticeSchedule/Release.vue
-->
<template>
  <div class="notice_container">
    <div class="arrow_card">
      <b class="b top_left"></b><b class="b top_right"></b><b class="b bottom_left"></b><b class="b bottom_right"></b>
      <div class="row-container">
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="card_title">新消息</div>
            <div class="card_content">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="rule-form">
                <el-form-item label="消息标题" prop="title">
                  <el-input v-model="ruleForm.title" placeholder="请输入消息标题" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <div class="content">
                  <div class="title">消息正文</div>
                  <RichText @editorChangeEvent="editorChangeEvent" />
                </div>

                <el-form-item label="添加附件 (上传附件合计不超过20MB)" prop="file">
                  <el-upload class="upload" :action="`${configBaseUrl}/api/platform/file/upload`"
                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove"
                    :before-upload="beforeAvatarUpload" multiple :limit="1" :on-exceed="handleExceed"
                    :file-list="fileList" :on-change="fileUploadCb" :headers="{ token }">
                    <div class="avatar-uploader-box">
                      <el-icon class="avatar-uploader-icon">
                        <plus />
                      </el-icon>
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="发送时间" prop="sendTime">
                  <el-date-picker v-model="ruleForm.sendTime" value-format="YYYY-MM-DD HH:mm:ss" type="datetime"
                    placeholder="请选择发送时间" style="width: 330px" :disabledDate="disabledDate"></el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="card_title">接收对象</div>
            <div class="card_content">
              <el-row :gutter="10">
                <el-col :span="11">
                  <PeopleGroupList @clearPeople="clearPeople" @removePeople="removePeople"
                    :recipientUserIds="recipientUserIds" />
                </el-col>
                <el-col :span="2">
                  <div class="switch-icon">
                    <el-icon>
                      <Switch />
                    </el-icon>
                  </div>
                </el-col>
                <el-col :span="11">
                  <UserContacts ref="userContacts" @checkedNodes="checkedNodes" />
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="action">
        <el-button type="primary" @click="submitForm('ruleForm')">发布</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </div>
    </div>

    <el-dialog v-model="dialogVisible" title="提示" width="500px" center :close-on-click-modal="false"
      :close-on-press-escape="false" custom-class="dialog">
      <div class="dialog-content" align="center">
        <el-icon v-if="dialogMessage === '消息发布成功'">
          <CircleCheckFilled style="color: #42a7fa" />
        </el-icon>
        <el-icon v-else>
          <WarningFilled style="color: #f05858" />
        </el-icon>
        <h3>{{ dialogMessage }}</h3>
      </div>
      <template #footer>
        <span class="dialog-action">
          <el-button type="primary" @click="iSeeEvent">{{ dialogMessage === "消息发布成功" ? "我知道了" : "确定" }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { config } from "@/config/index.js";
import { Plus, Switch, CircleCheckFilled, WarningFilled } from "@element-plus/icons";
import PeopleGroupList from "components/InsidePages/PeopleGroupList";
import UserContacts from "components/InsidePages/UserContacts";
import utils from "@/utils/index";
import RichText from "./components/rich-text";

export default {
  name: "noticeRelease",
  components: {
    RichText,
    Plus,
    Switch,
    CircleCheckFilled,
    WarningFilled,
    PeopleGroupList,
    UserContacts,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        content: "",
        contentText: "",
        sendTime: "",
        recipientUserIds: []
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入消息标题",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度不超于100",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入消息正文",
            trigger: "blur",
          },
          ,
          {
            min: 1,
            max: 3000,
            message: "长度不超于3000",
            trigger: "blur",
          },
        ],
      },

      fileTooBig: false,
      dialogVisible: false,
      dialogMessage: "",
      fileList: [],
      attach: "",
      recipientUserIds: [],
      configBaseUrl: config.baseUrl,
      token: localStorage.getItem("ACCESS_TOKEN"),
    };
  },
  methods: {
    // 禁用历史时间
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    // 富文本回调事件
    editorChangeEvent({ newHtml, newText }) {
      this.ruleForm.content = newHtml;
      this.ruleForm.contentText = newText;
    },
    //选择成员
    checkedNodes(data) {
      this.recipientUserIds = data;
    },
    // 删除单个成员
    removePeople(item) {
      this.recipientUserIds = this.recipientUserIds.filter((v) => v.id != item.id);
      this.$refs.userContacts.removeCheck(this.recipientUserIds);
    },
    // 清空成员列表
    clearPeople() {
      this.recipientUserIds = [];
      this.$refs.userContacts.clearCheck();
    },
    // 我知道了
    iSeeEvent() {
      this.dialogVisible = false;
      this.$router.push("/notice/initiate");
    },
    //添加消息接口请求
    addNoticeApi() {
      const recipientUserIds = [];
      this.recipientUserIds.forEach((item) => {
        recipientUserIds.push(item.id);
      });
      if (this.ruleForm.contentText === "") {
        return this.$message.warning("消息正文不能为空！");
      }
      if (this.ruleForm.contentText.length > 3000) {
        return this.$message.warning("消息正文不能大于3000个字符！");
      }

      if (recipientUserIds.length === 0) {
        return this.$message.warning("请添加接受对象！");
      }

      this.$api
        .addNotice({
          title: this.ruleForm.title,
          content: this.ruleForm.content,
          sendTime: this.ruleForm.sendTime,
          attach: this.attach,
          recipientUserIds,
        })
        .then((res) => {
          const { code } = res;
          if (code === "B200") {
            this.dialogVisible = true;
            this.dialogMessage = "消息发布成功";
          }
        });
    },
    //确定提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addNoticeApi();
        }
      });
    },
    resetForm(formName) {
      this.dialogVisible = true;
      this.dialogMessage = "确定取消发布？";
    },

    fileUploadCb(file, fileList) {
      const { response } = file;
      if (response && response.code === "B200") {
        this.attach = response.data;
      }
    },
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.fileTooBig = true;
        this.$message.error("文件最大只能上传20MB!");
      } else {
        this.fileTooBig = false;
      }
      return isLt20M;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning("暂时只能上传单个文件！");
    },
    beforeRemove(file, fileList) {
      if (this.fileTooBig) return;
      return this.$confirm(`是否删除该附件 ${file.name} ?`);
    },
  },
};
</script>

<style lang="scss" scoped>
.notice_container {
  padding: 20px 0;

  .arrow_card {
    height: 900px;
    background: #202331;
    width: 100%;

    .row-container {
      padding: 12px 0;
      height: 800px;
      overflow-y: scroll;
      overflow-x: hidden;

      .card_title {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }

    .action {
      display: flex;
      justify-content: center;

      .el-button {
        width: 170px;
        letter-spacing: 4px;
        font-size: 18px;
      }

      .el-button--default {
        margin-left: 70px;
      }
    }
  }

  .search-input {
    width: 400px;
    margin-right: 40px;
  }

  .rule-form {
    padding: 0 15px;

    .avatar-uploader-box {
      margin: 10px;
    }

    .avatar-uploader-icon {
      width: 80px;
      height: 80px;
    }
  }

  .switch-icon {
    display: flex;
    height: 100%;
    vertical-align: middle;
    align-items: center;
    justify-content: center;

    .el-icon {
      font-size: 26px;
      font-weight: bold;
      color: #4d4f5a;
    }
  }

  .pagination {
    margin-top: 15px;
  }

  .card_content {
    .content {
      margin-bottom: 20px;
      padding-top: 5px;

      .title {
        font-size: 18px;
        color: #fff;
        margin-bottom: 12px;

        &::before {
          content: "*";
          margin-right: 4px;
          color: #f56c65;
        }
      }
    }
  }
}
</style>
